import cloneDeep from 'lodash/cloneDeep';

import { undefDestroy } from '@webapp/common/lib/utils';
import type { QuestionType } from '@webapp/common/resources/survey';

import { mapCondition } from '../logic/map-logic';

import { initialCol, initialQuestion, initialQuestionParams, initialRow } from './questions';
import type { NestedItem, Question, QuestionCol, QuestionRow } from './questions';

export const mapRow = ({
    ANSWER,
    ANSWER_TEXT,
    EXCEPTION,
    FILE_ID,
    FILE_PATH,
    ID,
    IMAGE_HEIGHT,
    IMAGE_WIDTH,
    MIME_TYPE,
    ORDER,
    TYPE
}: any): QuestionRow => {
    const row: QuestionRow = {
        ...cloneDeep(initialRow),
        id: ID,
        value: ANSWER || '',
        type: TYPE,
        exception: !!EXCEPTION,
        fileId: FILE_ID,
        filePath: FILE_PATH || null,
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
        mimeType: MIME_TYPE,
        order: ORDER,
        text: ANSWER_TEXT
    };

    try {
        const value = JSON.parse(row.value as string);

        if (Array.isArray(value)) {
            row['value'] = value[0];
            row['value2'] = value[1];
            row['value3'] = value[2];
        }
    } catch (e) {}

    return row;
};

export const mapCol = ({ ANSWERS, EXCEPTION, ID, NAME, ORDER, NUMERIC_VALUE, TYPE, QUESTION_ID }: any): QuestionCol => {
    const col: QuestionCol = {
        ...cloneDeep(initialCol),
        id: ID,
        name: NAME === null ? '' : NAME,
        type: TYPE,
        exception: Boolean(EXCEPTION),
        order: ORDER,
        numeric_value: NUMERIC_VALUE,
        questionId: QUESTION_ID
    };

    try {
        col.answers = ANSWERS ? JSON.parse(ANSWERS) : []; // TODO fix backend
    } catch (e) {
        col.answers = [];
        console.error(e);
    }

    return col;
};

export const mapQuestion = (data: any, skipAdditional?: boolean): Question => {
    const {
        answers,
        groups,
        ID,
        logics,
        PAGE,
        parameters: { params },
        QUESTION,
        QUESTION_TYPE_ID,
        SERIAL
    } = data;
    const question: Question = {
        ...(!skipAdditional
            ? { ...cloneDeep(initialQuestion), typeMode: false }
            : { params: cloneDeep(initialQuestionParams) }),
        id: ID,
        name: QUESTION === null ? '' : QUESTION,
        pageId: PAGE,
        type: QUESTION_TYPE_ID as QuestionType,
        rows: answers.map(mapRow),
        cols: groups.map(mapCol),
        conditions: logics.map((logic: any) => mapCondition(logic, data)),
        serial: SERIAL
    };

    if (params) {
        try {
            question.params = Object.assign(cloneDeep(initialQuestion.params), {
                scaleType: 'custom',
                ...JSON.parse(params)
            });
        } catch (e) {
            console.error(e);
        }
    }

    return question;
};

export const reverseMapRow = ({ exception, fileId, order, type }: any): any =>
    undefDestroy({
        file_id: fileId,
        order,
        type,
        exception: exception !== undefined ? Number(exception) : undefined
    });

export const reverseMapQuestion = ({ name, type }: any): any =>
    undefDestroy({
        question: name || name === '' ? name : undefined,
        type
    });

export const reverseMapCol = (col: any): any => {
    if (col) {
        const { answers, exception, name, type, value, order, numericValue } = col;
        return undefDestroy({
            value,
            name,
            answers: answers ? JSON.stringify(answers) : undefined,
            type,
            order,
            numeric_value: numericValue,
            exception: exception !== undefined ? Number(exception) : undefined
        });
    }

    return {
        value: '',
        answers: []
    };
};

// TODO use hashmap, store by answerId
export const mapNested = ({
    ANSWER_ID,
    ID,
    LEVEL,
    NAME,
    PARENT_ID
}: {
    ID: number;
    ANSWER_ID: number;
    PARENT_ID: number;
    NAME: string;
    LEVEL: number;
}): NestedItem => ({
    id: ID,
    answerId: ANSWER_ID,
    name: NAME,
    level: LEVEL,
    parentId: PARENT_ID
});
