export var SurveyState;

(function (SurveyState) {
  SurveyState["idle"] = "idle";
  SurveyState["bootstrap"] = "bootstrap";
  SurveyState["loaded"] = "loaded";
  SurveyState["started"] = "started";
  SurveyState["fetchContent"] = "fetchContent";
  SurveyState["fetchQuotas"] = "fetchQuotas";
  SurveyState["welcomePage"] = "welcomePage";
  SurveyState["bundles"] = "bundles";
  SurveyState["updating"] = "updating";
  SurveyState["auth"] = "auth";
  SurveyState["authCheckVk"] = "authCheckVk";
  SurveyState["authCheckPwd"] = "authCheckPwd";
  SurveyState["completed"] = "completed";
  SurveyState["disabled"] = "disabled";
  SurveyState["err404"] = "err404";
  SurveyState["error"] = "error";
  SurveyState["blocked"] = "blokced";
  SurveyState["finishing"] = "finishing";
  SurveyState["finished"] = "finished";
  SurveyState["results"] = "results";
  SurveyState["testResults"] = "testResults";
})(SurveyState || (SurveyState = {}));