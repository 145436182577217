import dayjs from 'dayjs';
import { delCookie, getCookie, setCookie } from '@webapp/common/lib/cookies';
import { appendSearchParam, deleteSearchParam } from '@webapp/common/lib/search-params';
import { getSearchParams } from "./get-search-params";
export var TT_ID = 'tt_id';
export var tryToGetTtId = function tryToGetTtId() {
  var _getSearchParams$TT_I, _getSearchParams;

  return (_getSearchParams$TT_I = (_getSearchParams = getSearchParams()) === null || _getSearchParams === void 0 ? void 0 : _getSearchParams[TT_ID]) !== null && _getSearchParams$TT_I !== void 0 ? _getSearchParams$TT_I : getCookie(TT_ID);
};
export var setTtId = function setTtId(newTtId) {
  setTimeout(function () {
    appendSearchParam(TT_ID, newTtId);
  }, 100);
  setCookie(TT_ID, newTtId, {
    expires: dayjs().add(100, 'year').toDate()
  });
};
export var clearTtId = function clearTtId() {
  deleteSearchParam(TT_ID);
  delCookie(TT_ID);
};