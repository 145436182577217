import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { partsToSeconds } from '@webapp/common/lib/date';
import { pickIds, shuffleArray } from '@webapp/common/lib/utils';
import { QuestionType, withClickReply } from '@webapp/common/resources/constants';
import { BundleType } from '@webapp/common/resources/entities';
import { BundleEntity } from '@webapp/survey/src/entities/bundle';
import { withRandomPages } from "../guards";
import { getQ, loadBundlesOrder, loadTimer, storeBundlesOrder } from "../lib";
import { filterBundlesQuestionsByLogic } from "./logic";

var randomizePage = function randomizePage(page, pageQuestions) {
  // TODO ?? what this for
  var pickOrdered = function pickOrdered(ids) {
    return ids.map(function (id, idx) {
      var q = pageQuestions.find(function (q) {
        return getQ(q).id === id;
      });

      if (q) {
        q.send({
          type: 'SET_ORDER',
          value: idx + 1
        });
      } // FIXME?


      return q;
    });
  };

  if (page.shuffleOrder.length > 0) {
    return pickOrdered(page.shuffleOrder);
  }

  var alwaysLast = pageQuestions.filter(function (q) {
    return QuestionType.AGREEMENT === getQ(q).type;
  });
  var regular = pageQuestions.filter(function (q) {
    return QuestionType.AGREEMENT !== getQ(q).type;
  });
  var regularIds = pickIds(regular.map(function (q) {
    return {
      id: getQ(q).id
    };
  }));
  var ordered = pickOrdered(shuffleArray(regularIds)).concat(alwaysLast);
  page.shuffleOrder = ordered.map(function (o) {
    return parseFloat(o.getSnapshot().context.id.toString());
  });
  return ordered;
};

var packOneByOne = function packOneByOne(page, pageQuestions, bundles) {
  var questions = pageQuestions;

  if (page.random) {
    questions = randomizePage(page, pageQuestions);
  }

  questions.forEach(function (question) {
    bundles.push(new BundleEntity({
      type: BundleType.QUESTIONS,
      random: page.random,
      page: page
    }, [question]));
  });
  return bundles;
};

var packRandom = function packRandom(page, pageQuestions, bundles) {
  var questions = randomizePage(page, pageQuestions);
  bundles.push(new BundleEntity({
    random: true,
    type: BundleType.QUESTIONS,
    page: page
  }, questions));
  return bundles;
};

var packOrdinary = function packOrdinary(page, pageQuestions, bundles, clickReplyOn) {
  var split = true;
  var prevClickToReply = false;
  pageQuestions.forEach(function (q) {
    var clickReply = clickReplyOn && withClickReply(getQ(q));

    if (clickReply || split || prevClickToReply) {
      bundles.push(new BundleEntity({
        type: BundleType.QUESTIONS,
        page: page
      }, [q]));

      if (split) {
        split = false;
      }
    } else {
      bundles[bundles.length - 1].questions.push(q);
    }

    prevClickToReply = clickReply;
  });
  return bundles;
};

export var packBundles = function packBundles(pages, questions, clickReplyOn, globalLogic, queryParams, linkParams) {
  var bundles = pages.reduce(function (bundles, page) {
    var pageQuestions = questions.filter(function (q) {
      return getQ(q).page === page.id;
    });

    if (page.onebyone) {
      return packOneByOne(page, pageQuestions, bundles);
    } else if (page.random) {
      return packRandom(page, pageQuestions, bundles);
    } else {
      return packOrdinary(page, pageQuestions, bundles, clickReplyOn);
    }
  }, []);
  bundles = filterBundlesQuestionsByLogic(bundles, globalLogic, queryParams, linkParams);
  return bundles;
};
export var splitByTimerOn = function splitByTimerOn(timerOn, bundles) {
  var bundleWithTimerOnQuestion = bundles.find(function (_ref) {
    var questions = _ref.questions;
    return questions.find(function (q) {
      return getQ(q).id === timerOn;
    });
  });

  if (!bundleWithTimerOnQuestion) {
    console.error("splitByTimerOn: question ".concat(timerOn, " no found"));
    return bundles; // skip for non-existed question
  }

  var questions = bundleWithTimerOnQuestion.questions;

  if (questions.length < 2) {
    return bundles;
  }

  var newBundles = [];

  var _iterator = _createForOfIteratorHelper(bundles),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var b = _step.value;

      if (b !== bundleWithTimerOnQuestion) {
        newBundles.push(b);
        continue;
      }

      var timerQuestionIdx = questions.findIndex(function (q) {
        return getQ(q).id === timerOn;
      }); // @ts-ignore

      newBundles.push(_objectSpread(_objectSpread({}, bundleWithTimerOnQuestion), {}, {
        questions: questions && questions.slice(0, timerQuestionIdx + 1)
      }));

      if (questions[timerQuestionIdx + 1]) {
        // FIXME: types
        // @ts-ignore
        newBundles.push(_objectSpread(_objectSpread({}, bundleWithTimerOnQuestion), {}, {
          questions: questions.slice(timerQuestionIdx + 1)
        }));
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return newBundles;
};
export var splitByQuestionsWithTimer = function splitByQuestionsWithTimer(bundles) {
  var newBundles = [];

  var add = function add(page, questions) {
    newBundles.push(new BundleEntity({
      type: BundleType.QUESTIONS,
      page: page
    }, questions));
  };

  var _iterator2 = _createForOfIteratorHelper(bundles),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var b = _step2.value;
      var page = b.page;
      var questions = b.questions;
      var questionWithTimerIdx = questions.findIndex(function (q) {
        return !!getQ(q).params.timer;
      });

      if (questionWithTimerIdx < 0) {
        newBundles.push(b);
        continue;
      }

      while ((questionWithTimerIdx = questions.findIndex(function (q) {
        return !!getQ(q).params.timer;
      })) > -1) {
        var questionsBefore = questions.slice(0, questionWithTimerIdx);
        var questionWithTimer = questions.slice(questionWithTimerIdx, questionWithTimerIdx + 1);
        questions = questions.slice(questionWithTimerIdx + 1);

        if (questionsBefore.length > 0) {
          add(page, questionsBefore);
        }

        add(page, questionWithTimer);
      }

      if (questions.length > 0) {
        add(page, questions);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return newBundles;
};
export var calcCurrentBundleIndex = function calcCurrentBundleIndex(bundles) {
  var currentBundleIndex = 0;

  var _iterator3 = _createForOfIteratorHelper(bundles),
      _step3;

  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var questions = _step3.value.questions;
      var validAndAnswered = questions.every(function (q) {
        return getQ(q).dirty && !getQ(q).invalid;
      });
      var timers = questions.map(function (q) {
        var timerId = "question-timer-".concat(q.getSnapshot().context.id);
        return {
          q: q,
          timer: loadTimer(timerId)
        };
      }).filter(function (s) {
        return Boolean(s.timer);
      });
      var isAnyOfTimersExpired = timers.some(function (_ref2) {
        var q = _ref2.q,
            timer = _ref2.timer;
        var context = q.getSnapshot().context;
        var duration = partsToSeconds(context.params.hours, context.params.minutes, context.params.seconds);
        return timer + duration * 1000 < Date.now();
      });

      if (isAnyOfTimersExpired || validAndAnswered) {
        currentBundleIndex++;
      } else {
        break;
      }
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }

  return Math.min(currentBundleIndex, bundles.length - 1);
};

var sortBundleQuestions = function sortBundleQuestions(bundles) {
  return bundles.map(function (bundle) {
    bundle.questions = bundle.questions.slice().sort(function (a, b) {
      return getQ(a).order - getQ(b).order;
    });
    return bundle;
  });
};

export var prepareBundles = function prepareBundles(domain, info, pages, questions, calcIndex, clickReplyOn, globalLogic, queryParams, linkParams) {
  var _info$params$main = info.params.main,
      timer = _info$params$main.timer,
      timerOn = _info$params$main.timerOn;
  var bundles;
  var currentIndex;
  bundles = packBundles(pages, questions, clickReplyOn, globalLogic, queryParams, linkParams);

  if (timerOn && timer) {
    bundles = splitByTimerOn(timerOn, bundles);
  }

  bundles = splitByQuestionsWithTimer(bundles);

  if (withRandomPages(pages)) {
    bundles = loadBundlesOrder(domain, bundles);
  }

  bundles = sortBundleQuestions(bundles);

  if (withRandomPages(pages)) {
    setTimeout(function () {
      // потому что order выше проставляется асинхронно
      storeBundlesOrder(domain, bundles);
    }, 1500);
  }

  if (calcIndex) {
    // calc index used on init, afterward index incremented or decremented
    // this actually only used on load saved answers and can be store along with data
    currentIndex = calcCurrentBundleIndex(bundles);
  }

  return {
    bundles: bundles,
    currentIndex: currentIndex
  };
};