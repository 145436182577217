import type { QuestionModel } from './entities';
import type { QuestionActorRef, QuestionContext } from './entities/question-machine';

export enum DropdownType {
    DIFFERENT = 'different',
    COMMON = 'common'
}

export enum QuestionType {
    DEFAULT = 0, // during creation, type select

    ONE_OF_LIST = 4,
    FEW_OF_LIST = 6,

    SELECT_ONE_IMAGE = 24,
    SELECT_FEW_IMAGE = 25,

    DROPDOWN_LIST = 15,
    SCALE = 9,
    STAR = 14,
    SMILE = 20,
    DISTRIBUTE_SCALE = 27,
    NPS = 23,
    RANK = 18,

    MATRIX_SINGLE_ANSWER = 10,
    MATRIX_FEW_ANSWERS = 11,
    MATRIX_TEXT_ANSWER = 12,
    MATRIX_DROPDOWN_LIST = 13,
    MATRIX_STAR = 26,

    SEM_DIFF = 32,

    SHORT_TEXT = 1,
    LONG_TEXT = 2,
    NUMBER = 3,
    GROUP_FREE_ANSWERS = 33,

    FILE_UPLOAD = 16,
    RESIDENCE = 8,

    NESTED_LIST = 37,

    NAME = 28,
    EMAIL = 21,
    DATETIME = 22,
    PHONE = 29,
    AGREEMENT = 34,

    TEXT_BLOCK = 17,

    TEST_ONE_OF_LIST = 35,
    TEST_FEW_OF_LIST = 36,
    TEST_TEXT = 38,
    TEST_RANK = 39,
    CSI = 40
}

export enum AnswerType {
    SELF = 1,
    RADIO = 2,
    CHECKBOX = 3,
    SELECT = 4,
    TEXT = 5
}

export enum ResidenceType {
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
    CITY = 'CITY'
}

export const RESIDENCE_TYPE_LABEL = {
    [ResidenceType.COUNTRY]: 'Страна',
    [ResidenceType.REGION]: 'Регион',
    [ResidenceType.CITY]: 'Город'
};

export enum LogicType {
    ALWAYS = 0,
    SELECT_VARIANTS = 1,
    NOT_SELECT_VARIANTS = 2,
    SKIP = 3,
    TYPE_RESPONSE_QUOTA = 4,
    TYPE_QUESTION_QUOTA = 5,
    LINK_PARAM = 6
}

export enum LogicBoolFunc {
    OR = 'OR',
    AND = 'AND'
}

export enum LogicTransition {
    REGULAR_COMPLETE = 0,
    PAGE = 1,
    QUESTION = 2,
    DISQUAL = 3,
    PERSONAL_COMPLETE = 5,
    QUESTIONS = 6,
    PAGES = 7
}

export enum LogicTransitionType {
    TO_SURVEY = 0,
    REDIRECT_TO_WEBSITE = 1,
    TEXT = 2
}

export enum LogicAction {
    SHOW = 'SHOW',
    HIDE = 'HIDE'
}

export enum CompletePageType {
    SUCCESS_TEXT = 'SUCCESS_TEXT',
    REDIRECT_TO_WEBSITE = 'REDIRECT_TO_WEBSITE',
    TO_SURVEY = 'TO_SURVEY',
    SURVEY_RESULTS = 'SURVEY_RESULTS',
    TEST_RESULTS = 'TEST_RESULTS'
}

export const QuestionsWithClickReply = new Set([
    QuestionType.ONE_OF_LIST,
    QuestionType.SELECT_ONE_IMAGE,
    QuestionType.DROPDOWN_LIST,
    QuestionType.STAR,
    QuestionType.SCALE,
    QuestionType.SMILE,
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.NPS
]);

export const QuestionsWithPostSort = new Set([
    QuestionType.ONE_OF_LIST,
    QuestionType.FEW_OF_LIST,
    QuestionType.TEST_ONE_OF_LIST
]);

export const withClickReply = (question: QuestionContext): boolean => {
    if (question.type === QuestionType.DROPDOWN_LIST && question.params.dropdownType === 'multi') {
        return false;
    }
    return QuestionsWithClickReply.has(question.type);
};

export const QuestionWithoutHeader = new Set([QuestionType.AGREEMENT, QuestionType.TEXT_BLOCK]);

export const TypesWithScore = new Set([
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.TEST_FEW_OF_LIST,
    QuestionType.TEST_RANK,
    QuestionType.TEST_TEXT
]);

export const filterWithScore = (questions: Array<QuestionActorRef>): Array<QuestionActorRef> =>
    questions.filter((q) => TypesWithScore.has(q.getSnapshot().context.type));

export enum TestCheckStatus {
    NOT_CHECKED = 'NotChecked',
    VALID = 'valid',
    INVALID = 'invalid'
}

export const HIDE_ON_PRINT = new Set([
    QuestionType.MATRIX_DROPDOWN_LIST,
    QuestionType.MATRIX_TEXT_ANSWER,
    QuestionType.FILE_UPLOAD,
    QuestionType.SHORT_TEXT,
    QuestionType.LONG_TEXT,
    QuestionType.GROUP_FREE_ANSWERS,
    QuestionType.NUMBER,
    QuestionType.NAME,
    QuestionType.EMAIL,
    QuestionType.DATETIME,
    QuestionType.PHONE
]);

export const hideOnPrint = (t: QuestionType): boolean => HIDE_ON_PRINT.has(t);

const fontUrlTpl =
    'https://fonts.googleapis.com/css?family=${family}:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap&subset=cyrillic';

const makeUrl = (family: string): string => fontUrlTpl.replace(/ /gi, '+').replace('${family}', family);

export const FONTS = {
    Montserrat: makeUrl('Montserrat'),
    Arial: null,
    Georgia: null,
    Helvetica: null,
    Tahoma: null,
    'Times New Roman': null,
    'Trebuchet MS': null,
    Verdana: null,
    Lora: makeUrl('Lora'),
    'PT Serif Caption': makeUrl('PT Serif Caption'),
    'PT Serif': makeUrl('PT Serif'),
    'EB Garamond': makeUrl('EB Garamond'),
    Roboto: makeUrl('Roboto')
};

export const TYPES_NAMES: { [type: string]: string } = {
    [QuestionType.ONE_OF_LIST]: '1 из списка',
    [QuestionType.FEW_OF_LIST]: 'Несколько из списка',
    [QuestionType.SELECT_ONE_IMAGE]: 'Выбор изображения',
    [QuestionType.SELECT_FEW_IMAGE]: 'Выбор нескольких изображений',
    [QuestionType.DROPDOWN_LIST]: 'Выбор одного ответа',
    [QuestionType.SCALE]: 'Шкала Лайкерта',
    [QuestionType.STAR]: 'Звездный рейтинг',
    [QuestionType.SMILE]: 'Смайл рейтинг',
    [QuestionType.DISTRIBUTE_SCALE]: 'Распределительная шкала',
    [QuestionType.NPS]: 'NPS',
    [QuestionType.RANK]: 'Ранжирование',
    [QuestionType.MATRIX_SINGLE_ANSWER]: 'Выбор одного ответа',
    [QuestionType.MATRIX_FEW_ANSWERS]: 'Выбор нескольких ответов',
    [QuestionType.MATRIX_TEXT_ANSWER]: 'Текстовой ответ',
    [QuestionType.MATRIX_DROPDOWN_LIST]: 'Выпадающий список',
    [QuestionType.MATRIX_STAR]: 'Звездный рейтинг',
    [QuestionType.SEM_DIFF]: 'Семантический дифференциал',
    [QuestionType.SHORT_TEXT]: 'Короткий текст',
    [QuestionType.LONG_TEXT]: 'Длинный текст',
    [QuestionType.NUMBER]: 'Число',
    [QuestionType.GROUP_FREE_ANSWERS]: 'Группа свободных ответов',
    [QuestionType.FILE_UPLOAD]: 'Загрузка файла',
    [QuestionType.RESIDENCE]: 'Место жительства',
    [QuestionType.NAME]: 'Имя',
    [QuestionType.EMAIL]: 'E-mail',
    [QuestionType.DATETIME]: 'Дата/время',
    [QuestionType.PHONE]: 'Телефон',
    [QuestionType.AGREEMENT]: 'Галочка согласие',
    [QuestionType.TEXT_BLOCK]: 'Текст между вопросами',
    [QuestionType.TEST_ONE_OF_LIST]: 'Один из списка',
    [QuestionType.TEST_FEW_OF_LIST]: 'Несколько из списка'
};

export const QuestionsWithImages = new Set([QuestionType.SELECT_FEW_IMAGE, QuestionType.SELECT_ONE_IMAGE]);

export const withImages = (t: QuestionType): boolean => QuestionsWithImages.has(t);

export const filterWithImages = (qs: Array<QuestionModel>): Array<QuestionModel> =>
    qs.filter(({ type }) => withImages(type));
