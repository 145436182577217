export const appendSearchParam = (key: string, value: string) => {
    const sp = new URLSearchParams(window.location.search.slice(1));
    sp.set(key, value);
    const newUrl = `${window.location.pathname}?${sp.toString()}`;

    // Update URL without reload
    window.history.pushState(null, '', newUrl);
};

export const deleteSearchParam = (key: string) => {
    const sp = new URLSearchParams(window.location.search.slice(1));
    sp.delete(key);
    const newUrl = `${window.location.pathname}?${sp.toString()}`;

    // Update URL without reload
    window.history.pushState(null, '', newUrl);
};
