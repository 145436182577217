import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["savedResponse"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import cloneDeep from 'lodash/cloneDeep';
import { assign, sendTo, setup } from 'xstate';
import { initialQuestionResponse } from '@webapp/common/resources/entities';
export var QuestionResponseState;

(function (QuestionResponseState) {
  QuestionResponseState["ready"] = "ready";
})(QuestionResponseState || (QuestionResponseState = {}));

export var QuestionResponseMachine = setup({
  types: {
    context: {},
    events: {},
    input: {}
  }
}).createMachine({
  id: 'QuestionResponseMachine',
  initial: QuestionResponseState.ready,
  context: function context(_ref) {
    var _ref$input = _ref.input,
        savedResponse = _ref$input.savedResponse,
        input = _objectWithoutProperties(_ref$input, _excluded);

    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, cloneDeep(initialQuestionResponse)), input), savedResponse), {}, {
      dirty: false
    });
  },
  states: {
    ready: {
      on: {
        CLEAR: {
          actions: assign(function () {
            var empty = cloneDeep(initialQuestionResponse);
            return {
              value: empty.value,
              comment: empty.comment,
              extra: empty.extra
            };
          })
        },
        SET: {
          actions: assign(function (_ref2) {
            var event = _ref2.event;
            return {
              value: event.value
            };
          })
        },
        SET_EXTRA: {
          actions: assign(function (_ref3) {
            var event = _ref3.event;
            return {
              extra: event.value
            };
          })
        },
        SET_COMMENT: {
          actions: assign(function (_ref4) {
            var event = _ref4.event;
            return {
              comment: event.value
            };
          })
        },
        CHANGE: {
          actions: [sendTo(function (_ref5) {
            var context = _ref5.context;
            return context.parentRef;
          }, function (_ref6) {
            var context = _ref6.context,
                event = _ref6.event;
            return {
              type: 'RESPONSE_CHANGE',
              value: event.value,
              questionId: context.questionId,
              answerId: context.answerId,
              groupId: context.groupId
            };
          }), assign(function (_ref7) {
            var event = _ref7.event;
            return {
              value: event.value
            };
          })]
        },
        CHANGE_EXTRA: {
          actions: [sendTo(function (_ref8) {
            var context = _ref8.context;
            return context.parentRef;
          }, function (_ref9) {
            var context = _ref9.context,
                event = _ref9.event;
            return {
              type: 'RESPONSE_CHANGE_EXTRA',
              value: event.value,
              questionId: context.questionId,
              answerId: context.answerId,
              groupId: context.groupId
            };
          }), assign(function (_ref10) {
            var event = _ref10.event;
            return {
              extra: event.value
            };
          })]
        },
        CHANGE_COMMENT: {
          actions: [assign(function (_ref11) {
            var event = _ref11.event;
            return {
              comment: event.value
            };
          }), sendTo(function (_ref12) {
            var context = _ref12.context;
            return context.parentRef;
          }, function (_ref13) {
            var event = _ref13.event,
                context = _ref13.context;
            return {
              type: 'RESPONSE_CHANGE_COMMENT',
              value: event.value,
              questionId: context.questionId,
              answerId: context.answerId,
              groupId: context.groupId
            };
          })]
        }
      }
    }
  }
});