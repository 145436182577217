import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class DropdownListApi extends QuestionSimpleApi {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        if (question.params.dropdownType !== 'multi') {
            response.setValue(value);
            if (question.answers.length > 0) {
                question.answers.forEach((a) => {
                    a.response.setValue(a.id === value ? value : null);
                });
            }
        }

        const isExceptionSelected = question.answers.some((a) => a.exception && a.response.value);
        if (isExceptionSelected) {
            question.answers.forEach((a) => {
                if (!a.exception) {
                    a.response.setValue(false);
                }
            });
        }
    }

    public query({ answers, commentText, id, response, params }: ISurveyQuestionModel): AnswersRequest {
        if (params.dropdownType !== 'multi') {
            return {
                question: id,
                data: answers
                    .filter((a) => a.id.toString() === response.value.toString())
                    .map(({ id, value: [value1] }) => ({ answer: id, bool: true, text: value1 as string })),
                comment: commentText
            };
        } else {
            return {
                question: id,
                data: answers
                    .filter((a) => a.response.value)
                    .map(({ id, value: [value1] }) => ({ answer: id, bool: true, text: value1 as string })),
                comment: commentText
            };
        }
    }

    public validate(context: ISurveyQuestionModel): boolean {
        const {
            answers,
            params: { maxLength, minLength, required, dropdownType }
        } = context;
        if (!required) return true;

        if (dropdownType !== 'multi') {
            return super.validate(context);
        }

        const haveMaxLength = maxLength !== null;
        const haveMinLength = minLength !== null;
        let answeredCount = 0;
        let haveException = false;
        let valid = true;

        answers.forEach(({ exception, response, setValid, type }) => {
            if (!response) return;

            const value = !!response.value;
            const valid = response.value;

            if (valid) {
                ++answeredCount;
            }

            if (exception && value) {
                haveException = true;
            }
        });

        if (haveException) return true;

        if (required) {
            valid = answeredCount > 0;
        }

        if (haveMinLength) {
            valid = valid && answeredCount >= minLength;
        }

        if (haveMaxLength) {
            valid = valid && answeredCount <= maxLength;
        }

        return valid;
    }
}
