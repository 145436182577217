import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import cloneDeep from 'lodash/cloneDeep';
import { parseJSON, UnescapeHtml } from '@webapp/common/lib/utils';
import { initialPage, initialQuestionAnswer } from '@webapp/common/resources/entities';
import { IndicatorPosition } from '@webapp/common/resources/survey';
export var mapInfoParamsMain = function mapInfoParamsMain() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, data), {}, {
    timer: data.timer || false,
    timerHours: parseInt(data.timerHours, 10) || 0,
    timerMinutes: parseInt(data.timerMinutes, 10) || 0,
    timerSeconds: parseInt(data.timerSeconds, 10) || 0,
    disabledSurveyText: data.disabledSurveyText ? UnescapeHtml(data.disabledSurveyText) : null,
    surveyWasBlocked: data.surveyWasBlocked ? UnescapeHtml(data.surveyWasBlocked) : null,
    completePageText: data.completePageText ? UnescapeHtml(data.completePageText) : null,
    completeSurvey: data.completeSurvey,
    completeSurveyUrl: data.completeSurveyUrl,
    welcomePageText: data.welcomePageText ? UnescapeHtml(data.welcomePageText) : null
  });
};
export var mapInfoParamsTests = function mapInfoParamsTests() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    maxScoreAmount: isNaN(parseFloat(data.maxScoreAmount)) ? null : parseFloat(data.maxScoreAmount),
    passingScore: isNaN(parseFloat(data.passingScore)) ? null : parseFloat(data.passingScore),
    testResultLabel: typeof data.testResultLabel === 'string' ? UnescapeHtml(data.testResultLabel) : null,
    passingScoreLabel: typeof data.passingScoreLabel === 'string' ? UnescapeHtml(data.passingScoreLabel) : null,
    testPassed: typeof data.testPassed === 'string' ? UnescapeHtml(data.testPassed) : null,
    testNotPassed: typeof data.testNotPassed === 'string' ? UnescapeHtml(data.testNotPassed) : null,
    scoreLabel: typeof data.scoreLabel === 'string' ? UnescapeHtml(data.scoreLabel) : null,
    textDiapasons: Array.isArray(data.textDiapasons) ? data.textDiapasons : []
  };
};
export var mapInfoParamsShare = function mapInfoParamsShare() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    download: Boolean(data && data.download),
    filters: Boolean(data && data.filters),
    answers: Boolean(data && data.answers),
    reports: Boolean(data && data.reports)
  };
};
export var mapInfoDetails = function mapInfoDetails() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    answer_bool: data.ANSWER_BOOL,
    answer_group_id: data.ANSWER_GROUP_ID,
    answer_id: data.ANSWER_ID,
    answer_int: data.ANSWER_INT,
    answer_str: data.ANSWER_STR,
    answer_text: data.ANSWER_TEXT,
    comment: data.COMMENT,
    expired: data.EXPIRED,
    file_id: data.FILE_ID,
    question_id: data.QUESTION_ID,
    cant_rate: data.CANT_RATE
  };
};
export var mapInfoOther = function mapInfoOther() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, data), {}, {
    indicatorPosition: data.indicatorPosition || IndicatorPosition.BOTTOM
  });
};
export var mapInfo = function mapInfo() {
  var _data$details;

  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _data$rows = data.rows,
      $addons = _data$rows.$addons,
      $params = _data$rows.$params,
      ID = _data$rows.ID,
      template = _data$rows.template,
      TITLE = _data$rows.TITLE;
  return {
    id: ID,
    title: TITLE,
    complete: data.complete || false,
    newToken: data.newToken || null,
    template: template,
    hideFooter: Boolean($addons && $addons.hideFooter),
    params: {
      alert: parseJSON($params.alert),
      main: mapInfoParamsMain(parseJSON($params.main)),
      other: mapInfoOther(parseJSON($params.params)),
      tests: mapInfoParamsTests(parseJSON($params.tests)),
      share: mapInfoParamsShare(parseJSON($params.share))
    },
    design: {
      brand: parseJSON($params.brand),
      buttons: parseJSON($params.buttons),
      css: parseJSON($params.css || '{}'),
      fonts: parseJSON($params.fonts),
      view: parseJSON($params.view)
    },
    details: (_data$details = data.details) === null || _data$details === void 0 ? void 0 : _data$details.map(mapInfoDetails)
  };
};
export var mapQuestionAnswer = function mapQuestionAnswer() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var value = [data.ANSWER];
  var withFile = data.FILE_PATH || data.FILE_ID;

  try {
    value = JSON.parse(data.ANSWER).map(function (label) {
      return label && String(label) || '';
    });
  } catch (e) {}

  if (withFile) {}

  return _objectSpread(_objectSpread({}, cloneDeep(initialQuestionAnswer)), {}, {
    id: data.ID,
    value: value,
    type: data.TYPE && Number(data.TYPE),
    // TODO fix backend pass number core#121
    exception: Boolean(data.EXCEPTION),
    file: withFile ? {
      id: data.FILE_ID,
      path: data.FILE_PATH,
      name: data.FILE_NAME,
      // TODO check
      width: data.IMAGE_WIDTH,
      height: data.IMAGE_HEIGHT,
      mimeType: data.MIME_TYPE
    } : null
  });
};
export var mapQuestionGroup = function mapQuestionGroup(_ref) {
  var ANSWERS = _ref.ANSWERS,
      EXCEPTION = _ref.EXCEPTION,
      ID = _ref.ID,
      NAME = _ref.NAME,
      TYPE = _ref.TYPE,
      NUMERIC_VALUE = _ref.NUMERIC_VALUE;
  var answers = [];

  try {
    answers = ANSWERS ? JSON.parse(ANSWERS) : answers;
  } catch (e) {
    console.error(e);
  }

  return {
    id: ID,
    name: NAME,
    exception: Boolean(EXCEPTION),
    type: TYPE && Number(TYPE),
    // TODO fix backend, pass number  core#121
    answers: answers,
    numericValue: NUMERIC_VALUE
  };
};
export var mapQuestionLogic = function mapQuestionLogic() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    id: data.ID,
    type: data.TYPE,
    action: data.TRANSITION_ACTION,
    transition: data.GOAL,
    transitionType: data.TRANSITION_TYPE,
    boolFunc: data.BOOL_FUNC,
    toQuestion: data.NEXT_QUESTION,
    toQuestions: data.NEXT_QUESTIONS ? parseJSON(data.NEXT_QUESTIONS, []) : [],
    toPages: data.NEXT_PAGES ? parseJSON(data.NEXT_PAGES, []) : [],
    toSurvey: data.TO_SURVEY,
    toSurveyUrl: data.REWRITE,
    toPage: data.NEXT_PAGE,
    toWebsite: data.TO_WEB,
    completeText: data.COMPLETE_TEXT ? UnescapeHtml(data.COMPLETE_TEXT) : null,
    disqualText: data.BREAK_TEXT ? UnescapeHtml(data.BREAK_TEXT) : null,
    params: data.PARAMS ? parseJSON(data.PARAMS, []) : [],
    linkId: null,
    linkValue: null
  };
};
export var mapQuestion = function mapQuestion() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      answers = _ref2.answers,
      groups = _ref2.groups,
      ID = _ref2.ID,
      logics = _ref2.logics,
      PAGE = _ref2.PAGE,
      parameters = _ref2.parameters,
      QUESTION = _ref2.QUESTION,
      QUESTION_TYPE_ID = _ref2.QUESTION_TYPE_ID;

  var params = parseJSON(parameters.params, {}); // TODO webapp#583

  var minLength = !!params.minLength ? parseInt(params.minLength, 10) || 0 : undefined;
  var maxLength = !!params.maxLength ? parseInt(params.maxLength, 10) || 0 : undefined;
  return {
    id: ID,
    title: QUESTION ? UnescapeHtml(QUESTION) : '',
    page: PAGE,
    type: QUESTION_TYPE_ID,
    answers: answers.map(mapQuestionAnswer),
    groups: groups.map(mapQuestionGroup),
    logics: logics.map(mapQuestionLogic),
    params: _objectSpread(_objectSpread({}, params), {}, {
      minLength: minLength,
      maxLength: maxLength,
      timer: params.timer || false,
      hours: parseInt(params.hours, 10) || 0,
      minutes: parseInt(params.minutes, 10) || 0,
      seconds: parseInt(params.seconds, 10) || 0,
      helpText: params.helpText ? UnescapeHtml(params.helpText) : null,
      additionalText: params.additionalText ? UnescapeHtml(params.additionalText) : null,
      agreementLink: params.agreementLink ? UnescapeHtml(params.agreementLink) : null,
      agreementLeftLabel: params.agreementLeftLabel ? UnescapeHtml(params.agreementLeftLabel) : null,
      agreementRightLabel: params.agreementRightLabel ? UnescapeHtml(params.agreementRightLabel) : null,
      textBlock: params.textBlock ? UnescapeHtml(params.textBlock) : null,
      amount: params.amount && Number(params.amount),
      size: params.size && Number(params.size)
    })
  };
};
export var mapLinkParamsLogic = function mapLinkParamsLogic() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var params = JSON.parse(data.PARAMS);
  return {
    id: data.ID,
    action: data.TRANSITION_ACTION,
    type: data.TYPE,
    transition: data.GOAL,
    transitionType: data.TRANSITION_TYPE,
    boolFunc: data.BOOL_FUNC,
    toQuestion: data.NEXT_QUESTION,
    toQuestions: data.NEXT_QUESTIONS ? parseJSON(data.NEXT_QUESTIONS, []) : [],
    toPages: data.NEXT_PAGES ? parseJSON(data.NEXT_PAGES, []) : [],
    toSurvey: data.TO_SURVEY,
    toSurveyUrl: data.REWRITE,
    toPage: data.NEXT_PAGE,
    toWebsite: data.TO_WEB,
    completeText: data.COMPLETE_TEXT ? UnescapeHtml(data.COMPLETE_TEXT) : null,
    disqualText: data.BREAK_TEXT ? UnescapeHtml(data.BREAK_TEXT) : null,
    params: undefined,
    linkId: data.LINK_PARAM_ID,
    linkValue: params.linkParamValue
  };
};
export var mapQuestions = function mapQuestions(survey) {
  var questions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return questions.map(mapQuestion);
};
export var mapPage = function mapPage() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      ID = _ref3.ID,
      QUESTION_RANDOM_ORDERING = _ref3.QUESTION_RANDOM_ORDERING,
      QUESTION_SP = _ref3.QUESTION_SP,
      TITLE = _ref3.TITLE;

  return _objectSpread(_objectSpread({}, cloneDeep(initialPage)), {}, {
    id: ID,
    title: TITLE || '',
    onebyone: Boolean(QUESTION_SP),
    random: Boolean(QUESTION_RANDOM_ORDERING)
  });
};
export var mapPages = function mapPages() {
  var pages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return pages.map(mapPage);
};