import { useSelector } from '@xstate/react';
import { useStore } from "../store";
export var useStateSelector = function useStateSelector(selector) {
  var _useStore = useStore(),
      useRootSelector = _useStore.useSelector;

  var surveyMachine = useRootSelector(function (state) {
    return state.context.surveyMachine;
  });
  return useSelector(surveyMachine, function (sm) {
    return selector(sm.context, sm.value);
  });
};