import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import cloneDeep from 'lodash/cloneDeep';
import { assign, sendTo, setup } from 'xstate';
import { initialQuestionAnswer } from '@webapp/common/resources/entities';
import { QuestionResponseMachine } from "./response-machine";
export var AnswerState;

(function (AnswerState) {
  AnswerState["ready"] = "ready";
})(AnswerState || (AnswerState = {}));

export var QuestionAnswerMachineDefinition = setup({
  types: {
    context: {},
    events: {},
    input: {}
  }
}).createMachine({
  id: 'QuestionAnswerMachine',
  initial: AnswerState.ready,
  context: function context(_ref) {
    var input = _ref.input;
    return _objectSpread(_objectSpread({}, cloneDeep(initialQuestionAnswer)), input);
  },
  entry: [assign({
    response: function response(_ref2) {
      var _context$savedAnswer;

      var context = _ref2.context,
          spawn = _ref2.spawn,
          self = _ref2.self;
      return spawn(QuestionResponseMachine, {
        systemId: "question-".concat(context.questionId, "-answer-").concat(context.id, "-response"),
        input: {
          questionId: context.questionId,
          answerId: context.id,
          parentRef: self,
          savedResponse: (_context$savedAnswer = context.savedAnswer) === null || _context$savedAnswer === void 0 ? void 0 : _context$savedAnswer.response
        }
      });
    }
  })],
  states: _defineProperty({}, AnswerState.ready, {
    on: {
      RESPONSE_CHANGE: {
        actions: [sendTo(function (_ref3) {
          var context = _ref3.context;
          return context.parentRef;
        }, function (_ref4) {
          var event = _ref4.event;
          return _objectSpread(_objectSpread({}, event), {}, {
            type: 'QUESTION_RESPONSE_CHANGE'
          });
        })]
      },
      RESPONSE_CHANGE_COMMENT: {
        actions: [sendTo(function (_ref5) {
          var context = _ref5.context;
          return context.parentRef;
        }, function (_ref6) {
          var event = _ref6.event;
          return _objectSpread(_objectSpread({}, event), {}, {
            type: 'QUESTION_RESPONSE_CHANGE_COMMENT'
          });
        })]
      },
      // CHANGE_COMMENT: {
      //     actions: [
      //         sendTo(
      //             ({ context }) => context.parentRef,
      //             ({ event, context }) => ({
      //                 ...event,
      //                 type: 'QUESTION_RESPONSE_CHANGE_COMMENT',
      //                 questionId: context.questionId
      //             })
      //         )
      //     ]
      // },
      RESPONSE_CHANGE_EXTRA: {
        actions: [sendTo(function (_ref7) {
          var context = _ref7.context;
          return context.parentRef;
        }, function (_ref8) {
          var event = _ref8.event,
              context = _ref8.context;
          return _objectSpread(_objectSpread({}, event), {}, {
            type: 'QUESTION_RESPONSE_CHANGE_EXTRA',
            questionId: context.questionId
          });
        })]
      },
      SET_ANSWERED_COUNT: {
        actions: assign(function (_ref9) {
          var value = _ref9.event.value;
          return {
            answeredCount: value
          };
        })
      },
      SET_VALID: {
        actions: assign(function (_ref10) {
          var value = _ref10.event.value;
          return {
            valid: value
          };
        })
      },
      SET_CHECK_STATUS: {
        actions: assign(function (_ref11) {
          var testCheckStatus = _ref11.event.testCheckStatus;
          return {
            testCheckStatus: testCheckStatus
          };
        })
      }
    }
  })
});