import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import cloneDeep from 'lodash/cloneDeep';
import { initialQuestion } from 'resources/questions';

import { createDuration } from '@webapp/common/lib/date';
import type { QuestionAnswerActor, QuestionAnswerEntity } from '@webapp/survey/src/entities/answer-machine';
import { QuestionGroupEntity } from '@webapp/survey/src/entities/question-group';
import type { QuestionResponseActor } from '@webapp/survey/src/entities/response-machine';

import { AnswerType, QuestionType } from '../constants';
import type { QuestionGroupModel, QuestionModel, SurveyStore } from '../entities';

// TODO remove, use QuestionContext from machine
export interface QuestionEntity extends QuestionModel {
    answers: Array<QuestionAnswerEntity>;
    groups: Array<QuestionGroupEntity>;
    response: QuestionResponseActor;
    clickReply: boolean;
    timerLeft: Duration;
    residence?: Array<any>;
}

export const withSelfAnswer = (answers: Array<QuestionAnswerActor>): boolean =>
    answers.some((a) => a.getSnapshot().context.type === AnswerType.SELF);

export const createQuestion = (
    { groups, ...initial }: Partial<Omit<QuestionEntity, 'groups'>> & { groups: Array<QuestionGroupModel> },
    { info }: SurveyStore
) => {
    const q = {
        ...initialQuestion,
        ...cloneDeep(initial)
    } as Partial<QuestionEntity>;
    const { clickReply } = info.params.other;

    q.clickReply = clickReply;

    // q.answers = initial.answers.map((a) => new QuestionAnswerEntity(q.id, q.type, a));

    q.groups = (groups ?? []).map((g) => {
        return new QuestionGroupEntity(g /*, this.type*/);
    });

    q.answers = cloneDeep(initial.answers ?? []);

    if (q.params.timer) {
        q.timerLeft = dayjs.duration(
            q.params.hours * 60 * 60 * 1_000 + q.params.minutes * 60 * 1_000 + q.params.seconds * 1_000
        );
    } else {
        q.timerLeft = dayjs.duration(0);
    }

    switch (q.type) {
        case QuestionType.RESIDENCE:
            // console.error('implement');
            break;
        case QuestionType.FILE_UPLOAD:
            // console.error('implement');
            break;
        default:
            break;
    }

    return q;
};

export type createQuestionRes = ReturnType<typeof createQuestion>;

export const timerAgo = (q: QuestionEntity): Duration => {
    const { hours, minutes, seconds } = q.params;
    return createDuration(hours, minutes, seconds);
};

export class QuestionEntity implements QuestionEntity {}
