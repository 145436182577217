import cloneDeep from 'lodash/cloneDeep';

import {
    AlignSetting,
    BackgroundType,
    calcHeaderHeight,
    ColorScheme,
    getColor,
    HeaderBackgroundType,
    HeaderTitlePosition,
    placeholder,
    SubstrateType
} from '@webapp/common/lib/ui';

import { CompletePageType } from '../constants';
import { IndicatorPosition } from '../survey';

export interface ParamsAlertModel {
    required: string | null;
    amount: string | null;
    matrixAmount: string | null;
    numRange: string | null;
    indicator: string | null;
    fileMaxSize: string | null;
    fileExt: string | null;
    fileMaxAmount: string | null;
    comment: string | null;
    email: string | null;
    datetime: string | null;
    distribution: string | null;
    phone: string | null;
    yourAnswer: string; // TODO FIXME not used in survey
}

export const initialParamsAlert: Readonly<ParamsAlertModel> = {
    yourAnswer: 'Заполните поле "Свой ответ"',
    required: 'Вопрос является обязательным',
    amount: 'Необходимо ответов: min: {min}, max: {max}',
    matrixAmount: 'Необходимо ответов в каждой строке: min: {min}, max: {max}',
    numRange: 'Пожалуйста, введите число от {min} до {max}',
    fileMaxSize: 'Максимальный размер файла {max}',
    fileExt: 'Файл имеет недопустимый формат. Возможно загрузить файлы с расширениями: {formats}',
    fileMaxAmount: 'Максимальное количество файлов = {count}',
    comment: 'Комментарий к вопросу обязательный для заполнения',
    email: 'Неверный формат',
    datetime: 'Неверный формат',
    distribution: 'Распределите все {points} баллов',
    phone: 'Неверный формат',
    indicator: 'осталось заполнить'
};

export enum WelcomePageType {
    NOT = 'NOT',
    TO_SURVEY_PAGE = 'TO_SURVEY_PAGE',
    TO_SINGLE_PAGE = 'TO_SINGLE_PAGE'
}

export interface ParamsMainModel {
    welcomePage: WelcomePageType;
    welcomePageText: string | null;

    completePage: CompletePageType | null;
    completePageText: string | null;
    completeWebsite: string | null;
    completeSurvey: number | null;
    completeSurveyUrl?: string | null; // computed for survey

    disabledSurveyText: string | null;

    showResultTest: boolean | null;
    showDoneTest: boolean | null;

    savingNotComplete: boolean | null;

    passwordAccess: boolean; // false),
    passwordLabel: string | null;
    wrongPasswordLabel: string | null;

    individualPasswords: boolean | null;
    passwordWasActivated: string | null;
    surveyWasBlocked: string | null;
    anonymousPassword: boolean | null;

    vkAuth: boolean;

    timer: boolean;
    timerHours: number | null;
    timerMinutes: number | null;
    timerSeconds: number | null;
    timerOn: number | null;
    audio?: boolean;
}

export const initialParamsMain: Readonly<ParamsMainModel> = {
    welcomePage: WelcomePageType.NOT,
    welcomePageText: '',

    completePage: CompletePageType.SUCCESS_TEXT,
    completePageText: placeholder('Спасибо за выполнение!'),
    completeWebsite: '',
    completeSurvey: null,

    disabledSurveyText: placeholder('Опрос недоступен. Обратитесь к создателю опроса.'),

    showResultTest: true,
    showDoneTest: true,

    savingNotComplete: false,

    passwordAccess: false,
    passwordLabel: 'Пожалуйста, введите пароль',
    wrongPasswordLabel: 'Неверный пароль',

    individualPasswords: false,
    anonymousPassword: false,
    passwordWasActivated: 'Данный пароль уже был активирован',
    surveyWasBlocked: placeholder('Доступ к опросу заблокирован'),

    vkAuth: false,

    timer: false,
    timerHours: 0,
    timerMinutes: 30,
    timerSeconds: 0,
    timerOn: null // null - immediately after start
};

export interface ParamsOtherModel {
    showRequiredToQuestion: boolean;
    showQuestionNumber: boolean;
    backToQuestion: boolean;
    indicator: boolean;
    indicatorPosition: IndicatorPosition;
    clickReply: boolean;
    copyUrlParams: boolean;
    ipLimiter: boolean;
    disallowRefilling: boolean;
    disallowTextCopy: boolean;
}

export interface ParamsShareModel {
    download: boolean | null;
    filters: boolean | null;
    reports: boolean | null;
    answers: boolean | null;
}

export interface ParamsTestsDiapasonModel {
    from: number | null;
    to: number | null;
    text: string | null;
}

export interface ParamsTestsModel {
    maxScoreAmount: number | null;
    passingScore: number | null;
    testResultLabel: string | null;
    passingScoreLabel: string | null;
    scoreLabel: string | null;
    testPassed: string | null;
    testNotPassed: string | null;
    textDiapasons: Array<ParamsTestsDiapasonModel> | null;
}

export interface InfoParamsModel {
    alert: ParamsAlertModel;
    main: ParamsMainModel;
    other: ParamsOtherModel;
    tests: ParamsTestsModel;
    share: ParamsShareModel;
}

export const initialParamsOther: Readonly<ParamsOtherModel> = {
    showRequiredToQuestion: true,
    showQuestionNumber: true,
    backToQuestion: false,
    indicator: false,
    clickReply: false,
    ipLimiter: false,
    disallowRefilling: false,
    disallowTextCopy: false,
    copyUrlParams: false,
    indicatorPosition: IndicatorPosition.BOTTOM
};

export const initialParamsShare: Readonly<ParamsShareModel> = {
    download: false,
    filters: false,
    reports: false,
    answers: false
};
export const initialParamsTests: Readonly<ParamsTestsModel> = {
    // TODO wtf
    maxScoreAmount: '' as unknown as number,
    passingScore: '' as unknown as number,
    testResultLabel: 'Результат теста: ',
    passingScoreLabel: 'Проходной балл',
    scoreLabel: 'баллов',
    testPassed: 'Тест пройден',
    testNotPassed: 'Тест не пройден',
    textDiapasons: []
};
export const initialInfoParams: Readonly<InfoParamsModel> = {
    alert: cloneDeep(initialParamsAlert),
    main: cloneDeep(initialParamsMain),
    other: cloneDeep(initialParamsOther),
    tests: cloneDeep(initialParamsTests),
    share: cloneDeep(initialParamsShare)
};

export interface InfoViewModel {
    lineHeight: number;
    gap: number;
    questionBlockPosition: AlignSetting;
    buttonBlockPosition: AlignSetting;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    paddingBottom: number;
}

export interface ParamsBrandModel {
    background: string | null;
    substrate: string | null;
    backgroundColor: string | null;
    backgroundGradient: string | null;
    backgroundImage: string | null;
    backgroundTransparent: number;

    headerType: string | null;
    headerSize: number; // 0),
    headerBackgroundColor: string | null;
    headerBackgroundImage: string | null;
    headerStick: boolean;
    headerAllPage: boolean;
    headerTitlePosition: string | null;
    headerTitleAlign: AlignSetting | null;

    logo: string | null;
    logoPos: string | null;
    logoStyle: string | null;

    titleStyle: string | null;
}

export interface ParamsButtonsModel {
    replyButtonLabel: string;
    sendButtonLabel: string;
    backButtonLabel: string;
    nextButtonLabel: string;
}

export interface ParamsCssModel {
    text: string | null;
}

export interface ParamsFontsModel {
    fontFamily: string;

    titleSize: number;
    titleColor: string | null;
    titleBold: boolean;
    titleItalic: boolean;
    titleUnderline: boolean;
    titleThrough: boolean;

    questionTextSize: number;
    questionTextColor: string | null;
    questionTextBold: boolean;
    questionTextItalic: boolean;
    questionTextUnderline: boolean;
    questionTextThrough: boolean;

    replyTextSize: number;
    replyTextColor: string | null;
    replyTextBold: boolean;
    replyTextItalic: boolean;
    replyTextUnderline: boolean;
    replyTextThrough: boolean;

    elementColor: string | null;
    fieldColor: string | null;
    fontColor: string | null;
    buttonColor: string | null;
    buttonTextColor: string | null;
    buttonWidth?: number;
    buttonHeight?: number;
    buttonBorderRadius?: number;
    fieldBorderColor: string | null;
} /* .preProcessSnapshot((ss) => ({
        ...ss,
        // TODO fix backend core#282
        questionTextSize: parseInt(String(ss.questionTextSize), 10),
        replyTextSize: parseInt(String(ss.replyTextSize), 10),
        titleSize: parseInt(String(ss.titleSize), 10)
    })) */
export interface InfoDesignModel {
    brand: ParamsBrandModel;
    buttons: ParamsButtonsModel;
    css: ParamsCssModel;
    fonts: ParamsFontsModel;
    view: InfoViewModel;
}

export interface InfoDetailsModel {
    answer_bool: number | null;
    answer_group_id: number | null;
    answer_id: number | null;
    answer_int: number | null;
    answer_str: string | null;
    answer_text: string | null;
    comment: string | null;
    expired: string | null;
    file_id: number | null;
    question_id: number | null;
    cant_rate: number | null;
}

export interface InfoModel {
    id: number;
    title: string;
    complete: boolean;
    newToken: string | null;
    template: boolean;
    hideFooter: boolean;
    params: InfoParamsModel;
    design: InfoDesignModel;
    details?: Array<InfoDetailsModel>;
}

export const initialInfoView: Readonly<InfoViewModel> = {
    lineHeight: 2,
    gap: 3,
    questionBlockPosition: AlignSetting.LEFT,
    buttonBlockPosition: AlignSetting.LEFT,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    paddingBottom: 5
};
export const initialParamsBrand: Readonly<ParamsBrandModel> = {
    background: BackgroundType.COLOR,
    substrate: SubstrateType.EVERY_QUESTION,
    backgroundColor: getColor('white'),
    backgroundGradient: getColor('white'),
    backgroundImage: null,
    backgroundTransparent: 0,

    headerType: HeaderBackgroundType.TRANSPARENT,
    headerSize: 50,
    headerBackgroundColor: 'transparent',
    headerBackgroundImage: null,
    headerStick: false,
    headerAllPage: true,
    headerTitlePosition: HeaderTitlePosition.IN_HEAD,
    headerTitleAlign: AlignSetting.CENTER,

    logo: null,
    logoPos: '',
    logoStyle: '',

    // 230 * 0.5 + 40 = 155
    // 155 - 75 = 80
    // 80 * 100 / 155 = 25.8065 %
    titleStyle: `width: 50%; right: 25%; height: ${calcHeaderHeight(50)}px; top: 0`
};
export const initialParamsButtons: Readonly<ParamsButtonsModel> = {
    replyButtonLabel: 'Ответить',
    sendButtonLabel: 'Отправить',
    backButtonLabel: 'Назад',
    nextButtonLabel: 'Далее'
};
export const initialParamsCss: Readonly<ParamsCssModel> = {
    text: null
};
export const initialParamsFonts: Readonly<ParamsFontsModel> = {
    fontFamily: 'Montserrat',

    titleSize: 36,
    titleColor: getColor('grey3'),
    titleBold: true,
    titleItalic: false,
    titleUnderline: false,
    titleThrough: false,

    questionTextSize: 22,
    questionTextColor: getColor('grey3'),
    questionTextBold: true,
    questionTextItalic: false,
    questionTextUnderline: false,
    questionTextThrough: false,

    replyTextSize: 16,
    replyTextColor: getColor('grey3'),
    replyTextBold: false,
    replyTextItalic: false,
    replyTextUnderline: false,
    replyTextThrough: false,

    elementColor: getColor('blue'),
    fieldColor: getColor('blue'),
    fontColor: getColor('black'),
    buttonColor: getColor('blue'),
    buttonWidth: 164,
    buttonHeight: 40,
    buttonBorderRadius: 12,
    buttonTextColor: ColorScheme.LIGHT,
    fieldBorderColor: getColor('blue')
};
export const initialInfoDesign: Readonly<InfoDesignModel> = {
    brand: cloneDeep(initialParamsBrand),
    buttons: cloneDeep(initialParamsButtons),
    css: cloneDeep(initialParamsCss),
    fonts: cloneDeep(initialParamsFonts),
    view: cloneDeep(initialInfoView)
};
export const initialInfo: Readonly<InfoModel> = {
    id: 0,
    title: '',
    complete: false,
    newToken: null,
    template: false,
    hideFooter: true,

    params: cloneDeep(initialInfoParams),
    design: cloneDeep(initialInfoDesign)
};
