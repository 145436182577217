import dayjs from 'dayjs';
import isPlainObject from 'lodash/isPlainObject';

// ?? TODO https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs

export enum KeyType {
    DONT_ASK = 'dontask'
}

export enum LocalStorageKeys {
    LAZY_RELOAD = 'page-has-been-force-refreshed',
    COLOR_PALETTE = 'colors',
    DEVICE_FINGERPRINT = 'tt_fp',
    BACK_URL = 'back_url'
}

export type LocalKey =
    | LocalStorageKeys
    | `task_${string}_${string}`
    | `question_${string}_timer`
    | `question_details`
    | `new_question_details`
    | `${string}-invalid-password`
    | `${string}-invalid-password_new`
    | `answers__${string}`
    | `new_answers__${string}`
    | `bundles_random_${string}`
    | `new_bundles_random_${string}`
    | `timer-${string}`
    | `new_new_timer-${string}`
    | `survey_${string}_use_filters`
    | `survey_${string}_date_filters`
    | `survey_complete_${string}`
    | `preview_${string}`
    | 'superadmin'
    | `new_survey_complete_${string}`
    | `preview_${string}`;

const DEBUG = false;

const prepareValue = (v: any): string => (typeof v === 'string' ? v : JSON.stringify(v));
const parseValue = (v: string): any => {
    try {
        v = JSON.parse(v);
    } catch (e) {}
    return v;
};
const createTypeKey = (type: string, subKey: string): string => `${type}[${subKey}]`;

type Value = AnyObject | PrimitiveValue | Array<AnyObject | PrimitiveValue>;

export class SessionStorage {
    static get<T = Value>(key: string): T {
        const val = parseValue(sessionStorage.getItem(key));
        DEBUG && console.info(this.name, 'get', key, val);
        return val;
    }

    static set(key: string, val: Value): void {
        val = prepareValue(val);
        DEBUG && console.info(this.name, 'set', key, val);
        sessionStorage.setItem(key, val);
    }

    static setWithType(type: KeyType, subKey: string, val: Value): void {
        const key = createTypeKey(type, subKey);
        val = prepareValue(val);
        DEBUG && console.info(this.name, 'setWithType', key, val);
        sessionStorage.setItem(key, val);
    }

    static getWithType(type: KeyType, subKey: string): Value {
        const key = createTypeKey(type, subKey);
        const val = parseValue(sessionStorage.getItem(key));
        DEBUG && console.info(this.name, 'getWithType', key, val);
        return val;
    }
}

export class LocalStorage {
    static get<T = Value>(key: LocalKey): T {
        const val = parseValue(localStorage.getItem(key));
        DEBUG && console.info(this.name, 'get', key, val);
        if (val && val.expire) {
            const now = dayjs().unix();
            if (now >= val.expire) {
                LocalStorage.remove(key);
                return null;
            }
            return val.val;
        }
        return val;
    }

    static set<T = Value>(key: LocalKey, val: T, ttlSeconds?: number): void {
        const v = ttlSeconds
            ? prepareValue({
                  val,
                  expire: dayjs().add(ttlSeconds, 'second').unix()
              })
            : prepareValue(val);
        DEBUG && console.info(this.name, 'set', key, val);
        localStorage.setItem(key, v);
    }

    static append<T = Value>(key: LocalKey, val: T): void {
        const values = LocalStorage.get<Record<string | number, T>>(key);
        LocalStorage.set<Record<string | number, T>>(key, {
            ...values,
            ...val
        });
    }

    static filter<T = Value>(key: LocalKey, condition: (item: T) => boolean): AnyObject<T, number> | null {
        const values = LocalStorage.get<AnyObject<T, number>>(key);

        if (!isPlainObject(values)) return null;

        for (const k of Object.keys(values)) {
            if (!condition(values[k])) {
                delete values[k];
            }
        }

        LocalStorage.set(key, values);

        return values;
    }

    static remove(key: LocalKey): void {
        DEBUG && console.info(this.name, 'remove', key);
        localStorage.removeItem(key);
    }

    static clear(): void {
        DEBUG && console.info(this.name, 'clear', localStorage);
        localStorage.clear();
    }

    static clearExpired(): void {
        for (const k of Object.keys(localStorage)) {
            LocalStorage.get(k as LocalKey);
        }
    }
}
